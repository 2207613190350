import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, PRIMARY_OUTLET, Router, UrlTree } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SelectOption, SelectOptionValue } from 'form-field';
import { lang } from 'interfaces';
import { LanguageService } from 'language';
import { TranslatePipe } from 'translate';
import { MenuItemComponent, MenuItemLink } from '../menu-item/menu-item.component';
import { MenuComponent } from '../menu.component';

@Component({
  selector: 'lib-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
  imports: [MenuItemComponent, MenuComponent, TranslatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSelectorComponent implements OnInit, OnDestroy {
  lang = inject(LanguageService);
  private router = inject(Router);
  private cdr = inject(ChangeDetectorRef);
  @Input() collapseOnMobile = false;
  @Input() lightTheme = false;
  private languageChangeSubscription = Subscription.EMPTY;
  currentUrl: UrlTree;
  filteredLangs: SelectOption[] = [];

  get languageAgnosticPath(): string[] {
    if (this.currentUrl?.root.hasChildren()) {
      return this.currentUrl.root.children[PRIMARY_OUTLET].segments
        .map(s => s.path)
        .filter(p => p !== this.lang.current);
    }
    return ['/'];
  }

  constructor() {
    this.initLang();
  }

  initLang() {
    this.languageChangeSubscription = this.lang.onLangChange.subscribe(() => {
      this.lang.setLanguage(this.lang.current);
      this.setFilteredLangs();
      this.cdr.markForCheck();
    });
  }

  ngOnInit() {
    this.currentUrl = this.router.parseUrl(this.router.url);

    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(e => {
      this.currentUrl = this.router.parseUrl((e as NavigationEnd).urlAfterRedirects);
    });
  }

  ngOnDestroy() {
    this.languageChangeSubscription.unsubscribe();
  }

  getPath(lang: lang): string[] {
    return ['/', lang, ...this.languageAgnosticPath];
  }

  getMenuItemLink(lang: SelectOptionValue): MenuItemLink {
    return {
      routerLink: this.getPath(lang as lang),
      queryParams: this.currentUrl?.queryParams,
      queryParamsHandling: 'merge',
      fragment: this.currentUrl?.fragment,
    };
  }

  private setFilteredLangs() {
    this.filteredLangs = this.lang.langs
      .filter(lang => lang !== this.lang.current)
      .map(lang => ({
        value: lang,
        label: this.lang.langsFull[lang],
      }));
  }
}
