@if (a11yAltText()) {
  <p class="sr-only" [id]="a11yAltTextId">{{ a11yAltText() }}</p>
}
<button
  mat-button
  type="button"
  class="kitsune-menu !min-w-12"
  [ngClass]="{
    '!border-solid !border-[0.5px] !h-12': outlined(),
    'kitsune-menu__collapse': collapseOnMobile(),
  }"
  [class]="buttonClass()"
  [attr.aria-labelledby]="a11yAltText() ? a11yAltTextId : null"
  aria-haspopup="menu"
  (click)="open()"
  #menuButton
>
  <div class="flex items-center">
    @if (icon()) {
      <lib-svg
        [svg]="icon()"
        [class]="iconClass()"
        [ngClass]="{
          'fill-[--neutral-800]': lightTheme(),
          'fill-white': !lightTheme(),
          'sr-only sm:not-sr-only !hidden lg:!block': collapseOnMobile(),
        }"
      />
    }
    @if (label()) {
      <span
        class="kitsune-menu__label font-bold text-xs xl:text-base text-nowrap"
        [ngClass]="{
          'text-[--neutral-800]': lightTheme(),
          'text-white': !lightTheme(),
        }"
        [class]="textClass()"
      >
        {{ label() }}
      </span>
    }
    @if (displayArrow()) {
      <lib-svg
        svg="fontawesome/chevron-down"
        class="ml-2"
        [ngClass]="{
          'sr-only sm:not-sr-only !hidden lg:!block': collapseOnMobile(),
          'fill-[--neutral-800]': lightTheme(),
          'fill-white': !lightTheme(),
        }"
      ></lib-svg>
    }
  </div>
</button>
<ng-template #menuContent>
  @for (item of menuItems; track item) {
    @if (item.menuItemLink()?.routerLink || item.menuItemLink()?.rawUrl) {
      <div class="flex items-center hover:bg-[--color-gray-opacity]">
        @if (item.icon()) {
          <lib-svg
            [svg]="item.icon()"
            class="mr-1 ml-3"
            [ngClass]="{
              'fill-[--neutral-800]': lightTheme(),
              'fill-white': !lightTheme(),
            }"
          ></lib-svg>
        }
        @if (item.menuItemLink().routerLink) {
          <a
            [ngClass]="{
              'mx-4': !item.icon(),
            }"
            mat-menu-item
            [routerLink]="item.menuItemLink().routerLink"
            [routerLinkActive]="item.menuItemLink().routerLinkActive ?? ''"
            [queryParams]="item.menuItemLink().queryParams"
            [queryParamsHandling]="item.menuItemLink().queryParamsHandling"
            [fragment]="item.menuItemLink().fragment"
          >
            <ng-template [ngTemplateOutlet]="item.template"></ng-template>
          </a>
        }
        @if (item.menuItemLink().rawUrl) {
          <a class="mx-2" mat-menu-item [attr.href]="item.menuItemLink().rawUrl">
            <ng-template [ngTemplateOutlet]="item.template"></ng-template>
          </a>
        }
      </div>
    } @else {
      <div class="flex items-center hover:bg-[--color-gray-opacity]">
        @if (item.icon()) {
          <lib-svg
            [svg]="item.icon()"
            class="mx-1"
            [ngClass]="{
              'fill-[--neutral-800] ml-4': lightTheme(),
              'fill-white': !lightTheme(),
            }"
          ></lib-svg>
        }
        <button mat-menu-item type="button" class="block w-full mx-2" (click)="close()">
          <ng-template [ngTemplateOutlet]="item.template"></ng-template>
        </button>
      </div>
    }
  }
</ng-template>
