<lib-menu
  [label]="lang.current.toUpperCase()"
  [a11yAltText]="['langSwitcher.alt.text'] | translate"
  [collapseOnMobile]="collapseOnMobile"
  [lightTheme]="lightTheme"
  [outlined]="true"
  [displayArrow]="false"
  buttonClass="!border-[--color-border-primary]"
>
  @for (lang of filteredLangs; track lang) {
    <lib-menu-item [menuItemLink]="getMenuItemLink(lang.value)">
      <span class="font-bold" [attr.lang]="lang.value">{{ lang.label }}</span>
    </lib-menu-item>
  }
</lib-menu>
