.menu-global {
  z-index: 9999;
  position: fixed;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease;
  margin-top: 5px;

  .menu {
    min-width: 95px;
    background-color: white;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 4px;
    box-shadow:
      0 2px 4px -1px #0003,
      0 4px 5px #00000024,
      0 1px 10px #0000001f;
    transform-origin: top left;
    transition: transform 0.2s ease;
    transform: scale(0.9);
  }

  &.on-top {
    .menu {
      transform-origin: bottom left;
    }
  }

  &.open {
    pointer-events: unset;
    opacity: 1;
    .menu {
      transform: scale(1);
    }
  }

  a,
  button {
    user-select: none;
    cursor: pointer;
    outline: none;
    border: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    line-height: 48px;
    height: 48px;
    text-align: left;
    text-decoration: none;
    position: relative;
    text-align: left;

    // font-family: Nunito,sans-serif;
    font-size: 14px;
    font-weight: 400;

    &.focused {
      background: rgba(0, 0, 0, 0.04);
    }
  }
}

.menu-global-bg {
  position: fixed;
  inset: 0;
  z-index: 9998;
}
